<template>
  <div class="loading-small">
    <img src="@/assets/loading.gif" alt="loading">
  </div>
</template>

<script>
export default {
  name: "LoadingSmall",
};
</script>

<style scoped>
.loading-small
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hwb(0deg 100% 0% / 50%);
    z-index: 10;
}
.loading-small img{
    width: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>