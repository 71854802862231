<template>
  <div class="modal-header">
    <slot>

    </slot>
  </div>
</template>

<script>
export default {
  name: "ModalHeader"
}
</script>

<style scoped>
.modal-header {
  padding:20px 40px;
  font-weight:bold;
  font-size:16px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>