import Axios from 'axios';
export const menu = {
    state: () => ({
        isNavOpen: false
    }),
    mutations: {
        setIsNavOpen(state, payload) {
            state.isNavOpen = payload
        },
    },
    actions: {
        toggleNav(context) {
            var isNavOpen = !context.getters.getIsNavOpen;
            context.commit('setIsNavOpen', isNavOpen);
        },
        openMgc(context, params = {}) {

            context.commit('setIsNavOpen', false);

            Axios.get(`${process.env.VUE_APP_AJAX_HOST}/api/mgc/togo`, {
                params: params,
            }).then((response) => {
                if (response.data.link !== undefined) {
                    window.open(response.data.link, '_blank');
                } else if (response.data.status === "error") {
                    alert(response.data.message)
                } else {
                    alert("Произошла ошибка на сервере")
                }
            });
        },
    },
    getters: {
        getIsNavOpen: (state) => {
            return state.isNavOpen
        },
    }
}