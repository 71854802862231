<template>
  <div class="sidebar">
    <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
    <transition name="slide">
      <div v-if="isPanelOpen" class="sidebar-panel">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "Sidebar",
  computed: {
    isPanelOpen() {
      return this.$store.getters.getIsNavOpen
    }
  },
  methods: {
    closeSidebarPanel() {
      this.$store.dispatch("toggleNav");
    }
  }
}
</script>
<style>
.slide-enter-active,
.slide-leave-active {
  transition: 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}

.sidebar-backdrop {
  background-color: rgb(0 0 0 / 20%);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index:999;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  padding: 15px;
  width: 300px;

}
</style>
