<template>
  <!-- <Loading /> -->
  <Header></Header>
  <Container></Container>
  <Footer></Footer>
</template>

<script>
export default {
  methods: {
    setAxiosToken() {
      this.$store.dispatch('setAxiosToken');
    },
    setAxiosError() {
      this.$store.dispatch('setAxiosError');
    },
    setRouterEach() {
      this.$router.beforeEach((to, from, next) => {
          if (to.matched.some(record => record.meta.requiresAuth)) {
              if (this.$store.getters.getIsAuthUser) {
                  next()
                  return
              }
              next('/login')
          } else {
              next()
          }
      })
      this.$router.afterEach((to, from) => {
        this.$metrika.hit(to.href, {
          title: to.name,
          referer: from.href
        })
      })
    },
  },
  created: function () {
    this.setAxiosToken();
    this.setAxiosError();
    this.setRouterEach();
  },
};
</script>

<style lang="scss">
@import "styles/index.scss";
</style>
