<template>
    <div>
        <select 
                class="form-select mb-2 form-control custom-select-control" 
                v-model="select"
                :class="{'floating-input-error': error}"
                :required="required"  
                >
            <option disabled="disabled" value="">{{ text }}</option>
            <option v-for="(item, index) in items"
                :key="index"
                :value="item.value">{{item.name}}</option>
        </select>
      <small class="floating-text-error" v-if="required && error">Поле явлется обязательным</small>
    </div>
</template>

<script>
export default {
  name: "CustomSelectWithLabel",
  props: {
    text: String,
    items: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      select: '',
    };
  },
  watch: {
    // при каждом изменении `select` эта функция будет запускаться
    select(newQuestion) {
      this.$emit('change', newQuestion)
    },
  },
}
</script>

<style scoped>
.floating-label {
  position:relative;
  margin-bottom:20px;
}
.floating-input {
  font-size:14px;
  display:block;
  width:100%;
  border-radius: 8px;
  background-color: #f2f3f4;
  border:none;
  height: 54px;
  padding: 15px 15px 0px 15px;
  margin-top:15px;

}
.floating-input:focus {
  outline:none;
}
label {
  color:#999;
  font-size:14px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:15px;
  top:15px;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}
.floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
  top:5px;
  font-size:12px;
  color:#3663f2;
}

.custom-select-control {
    font-size: 14px;
    display: block;
    width: 100%;
    border-radius: 8px;
    background-color: #f2f3f4;
    border: none;
    height: 54px;
    margin-top: 15px;
    border: 1px solid transparent;
}

.floating-input-error{
    border-color: #d79090;
}
.floating-text-error{
  color: #cf1c1c;
  padding-left: 3px;
}

@media (max-width: 768px) {}
</style>
