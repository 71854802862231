<template>

  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="close" @click="$emit('close')"> <span class="material-icons">close</span> </div>
        <slot>

        </slot>
      </div>
    </div>
  </div>


</template>

<script>
export default {
name: "Modal",
}
</script>
<style scoped lang="scss">
.close {
  position:absolute;
  top:10px;
  right:10px;
  z-index:9999;
  cursor: pointer;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 100%;
  max-width: 500px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  border-radius:6px;
  position:relative;
}
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

@media (max-width: 567px) {
  .modal-container {
    width: 100%;
    max-width: 400px;
    margin: 0px auto;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    border-radius:6px;
  }
}

@media (max-width: 414px) {
  .modal-container {
    max-width: 370px;
  }
}

@media (max-width: 375px) {
  .modal-container {
    max-width: 330px;
  }
}

@media (max-width: 320px) {
  .modal-container {
    max-width: 300px;
  }
}

@media (max-width: 280px) {
  .modal-container {
    max-width: 260px;
  }
}

</style>