<template>
  <div class="load-block" :style="{
    'width': width,
    'height': height,
    'background': color
  }"><slot></slot></div>
</template>

<script>
export default {
  name: "Loadblock",
  props: {
    width: String,
    height: String,
    color: {
      type: String,
      default: '#D9D9D9'
    },
  }
};
</script>

<style scoped>
.load-block
{
  border-radius: 4px;

  display: inline-block;
}
</style>