import Axios from 'axios';
export const summarizing = {
    state: () => ({
        summarizingData: {
            current_position: {
                quarter: 0,
                year: '2022'
            },
            earned_third_month: 0,
            recieved_third_month: 0,
            earned_second_month: 0,
            recieved_second_month: 0,
            earned_first_month: 0,
            recieved_first_month: 0,
            recieved_per_whole_quarter: 0,
            sold_tm_amount: 0,
            achieved_level: "Новичок",
            first_name: "первом",
            second_name: "втором",
            third_name: "третьем",
        },
        summarizingDataResult: {
            earned_points_this_month: 0,
            active_points: 0,
            quarter: 0,
            current_level: "Новичок",
        }


    }),
    mutations: {
        setSummarizingData(state, payload) {
            state.summarizingData = payload
        },
        setSummarizingDataResult(state, payload) {
            state.summarizingDataResult = payload
        },

    },
    actions: {
        summarizingData(context, params) {
            Axios.get(`${process.env.VUE_APP_AJAX_HOST}/api/get-summarizing`, {
                params: params,

            }).then((response) => {
                console.log(response)
                context.commit('setSummarizingData', response.data);

            });
        },
        //DODELAT TUT CHO NADO
        summarizingDataResult(context, params) {
            Axios.get(`${process.env.VUE_APP_AJAX_HOST}/api/get-summarizing-result`, {
                params: params,

            }).then((response) => {
                context.commit('setSummarizingDataResult', response.data);

            });
        },


    },
    getters: {
        getSummarizingDataResult: (state) => {
            return state.summarizingDataResult
        },
        getSummarizingData: (state) => {
            return state.summarizingData
        },

    }
}
