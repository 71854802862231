<template>
  <div class="row block">
    <div class="col-md-6">
      <div class="logo">
        <img src="../images/logo.png" class="img-fluid" />
      </div>
      <div class="text">
        Программа лояльности для розничных продавцов брендов: <br />
        Ekko, Ekko by Maxonor, Vigro, <br />
      </div>
      <div>
        <template v-if="getIsAuthUser === false">
          <modal-main-button
            class="button"
            @click="$router.push({ name: 'Registration' })"
            >Зарегистрироваться</modal-main-button
          >
        </template>
        <template v-else>
          <modal-main-button
            class="button"
            @click="$router.push({ name: 'SaleAdd' })"
            >Заработать баллы</modal-main-button
          >
        </template>
      </div>
    </div>
    <div class="col-md-6">
      <div><img src="../images/loyalty.png" class="img-fluid" /></div>
    </div>
  </div>

  <div class="row block">
    <div class="col-md-6 order-2 order-md-1 stm-logo">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="stm-logo-block">
            <img src="../images/stm/ekko.png" class="img-fluid" />
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="stm-logo-block">
            <img src="../images/stm/ekko-by-maxonor.png" class="img-fluid" />
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="stm-logo-block">
            <img src="../images/stm/vigro.png" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 order-1 order-md-2">
      <div class="heading blue-line">
        Продавай смесители, мойки и душевые системы
      </div>
      <div class="text">
        брендов: Ekko, Ekko by Maxonor, Vigro, <br />
      </div>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-md-6">
      <div class="heading orange-line">Сканируй уникальный QR-код</div>
      <div class="text">
        Копи баллы  и получай сертификаты лучших интернет-магазинов, а также множество других призов 
      </div>
      <div>
        <template v-if="getIsAuthUser === false">
          <modal-main-button
            class="button"
            @click="$router.push({ name: 'Registration' })"
            >Зарегистрироваться</modal-main-button
          >
        </template>
        <template v-else>
          <modal-main-button
            class="button"
            @click="$router.push({ name: 'SaleAdd' })"
            >Заработать баллы</modal-main-button
          >
        </template>
      </div>
    </div>
    <div class="col-md-6">
      <div class="loyalty-image">
        <img src="../images/loyalty-2.png" class="img-fluid" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
.block {
  margin-bottom: 180px;
}
.logo img {
  margin-bottom: 10px;
}
.button {
  width: 260px !important;
  margin-top: 25px;
}
.text {
  font-size: 25px;
  line-height: 34px;
  color: #3d3b3b;
}
.heading {
  font-weight: bold;
  font-size: 40px;
  line-height: 54px;
  color: #171a1f;
  margin-bottom: 40px;
  position: relative;
}
.stm-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.blue-line:after {
  content: "";
  width: 190px;
  height: 14px;
  background: #59affe;
  position: absolute;
  bottom: -20px;
  left: 0px;
  z-index: 1;
}
.orange-line:after {
  content: "";
  width: 190px;
  height: 14px;
  background: #ff8c05;
  position: absolute;
  bottom: -20px;
  left: 0px;
  z-index: 1;
}

@media (max-width: 768px) {
  .block {
    margin-bottom: 100px;
  }
  .text {
    font-size: 18px;
    text-align: center;
    line-height: 25px;
    margin-bottom: 20px;
  }
  .button {
    width: 100% !important;
    margin: 20px auto;
  }
  .heading {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
  }
  .blue-line:after,
  .orange-line:after {
    right: 0px;
    margin: 0 auto;
  }
  .loyalty-image {
    display: none;
  }
}
</style>
