import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import Axios from 'axios';
import VueAxios from 'vue-axios'
// import VueYandexMetrika from 'vue3-yandex-metrika'

import BootstrapVue3 from 'bootstrap-vue-3'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const QrScanner = require('qr-scanner');

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import componentsUi from "@/components/UI/"
import components from "@/components/"

import Maska from 'maska'

const app = createApp(App)

components.forEach(component => {
    app.component(component.name, component)
})

componentsUi.forEach(component => {
    app.component(component.name, component)
})



import './plugins'
import './plugins/fortawesome'
import './components'

app
/**
 * Store
 */
    .use(store)
    /**
     * QrScanner
     */
    .use(QrScanner)
    /**
     * BootstrapVue3
     */
    .use(BootstrapVue3)
    /**
     * Axios
     */
    .use(VueAxios, Axios)
    /**
     * Router
     */
    .use(router)
    /**
     * Maska
     */
    // use as plugin
    .use(Maska)
    // or as directive
    // .directive('maska', Maska.maska)
    /**
     * FontAwesomeIcon
     */
    .component("font-awesome-icon", FontAwesomeIcon)
    /**
     * APP
     */
    .mount('#app')


// .use(VueYandexMetrika, {
//     id: 87422576,
//     env: process.env.NODE_ENV
// })

export default app