<template>
  <div>
    <button :sendForm="sendForm" class="main-button" @click="addLoading" :class="{loading: isActive}" :disabled="isActive || buttonDisabled">
      <span class="main-button-loading">
        <preloader-white />
      </span>
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "MainButton",
  props: {
    buttonDisabled: Boolean,
    isActive: Boolean,
  },
  data () {
    return {

    }
  },
  methods: {
    addLoading: function () {
      this.$emit("send")
    }
  },
}
</script>

<style scoped>
.main-button {
  background: #3663F2;
  box-shadow: 0px 8px 24px rgba(54, 99, 242, 0.35), 0px 0px 48px rgba(54, 99, 242, 0.2);
  border-radius: 7px;
  font-weight: bold;
  font-size: 16px;
  color:#FFFFFF;
  text-align:center;
  /* max-width:350px; */
  width:100%;
  height:40px;
  border:none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-button:hover {
  background: #1344E0;
}
.main-button.loading {
  background: #5B82FC;
}
.main-button-loading {
  display:none;
}
.main-button.loading > .main-button-loading {
  display:flex;
}
.main-button:disabled {
  background: #779aff;
}
@media (max-width: 768px) {
  .main-button {
    width:100%;
  }
}
</style>
