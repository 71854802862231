import CustomSelect from '@/components/UI/CustomSelect.vue'
import MainButton from '@/components/UI/MainButton.vue'
import MainButtonResponsive from '@/components/UI/MainButtonResponsive.vue'
import SecondaryButton from '@/components/UI/SecondaryButton.vue'
import NotifyPrimary from '@/components/UI/NotifyPrimary.vue'
import NotifyDanger from '@/components/UI/NotifyDanger.vue'
import NotifySuccess from '@/components/UI/NotifySuccess.vue'
import CustomInput from '@/components/UI/CustomInput.vue'
import CustomInputPassword from '@/components/UI/CustomInputPassword.vue'
import CustomInputWithText from '@/components/UI/CustomInputWithText.vue'
import CustomInputWithLabel from '@/components/UI/CustomInputWithLabel.vue'
import CustomSelectWithLabel from '@/components/UI/CustomSelectWithLabel.vue'
import Tooltip from '@/components/UI/Tooltip.vue'
import Modal from '@/components/UI/Modal/Modal.vue'
import ModalHeader from '@/components/UI/Modal/ModalHeader.vue'
import ModalBodyLeft from '@/components/UI/Modal/ModalBodyLeft.vue'
import ModalBodyCenter from '@/components/UI/Modal/ModalBodyCenter.vue'
import ModalBodyOnly from '@/components/UI/Modal/ModalBodyOnly.vue'
import ModalFooterCenter from '@/components/UI/Modal/ModalFooterCenter.vue'
import ModalFooterRight from '@/components/UI/Modal/ModalFooterRight.vue'
import ModalMainButton from '@/components/UI/Modal/ModalMainButton.vue'
import ModalSecondaryButton from '@/components/UI/Modal/ModalSecondaryButton.vue'
import TextBlock from '@/components/UI/TextBlock.vue'
import PreloaderWhite from '@/components/UI/PreloaderWhite.vue'
import PreloaderGrey from '@/components/UI/PreloaderGrey.vue'
import PreloaderBlue from '@/components/UI/PreloaderBlue.vue'



export default [
    CustomSelect,
    MainButton,
    MainButtonResponsive,
    SecondaryButton,
    NotifyPrimary,
    NotifyDanger,
    NotifySuccess,
    CustomInput,
    CustomInputPassword,
    CustomInputWithText,
    CustomInputWithLabel,
    CustomSelectWithLabel,
    Tooltip,
    Modal,
    ModalHeader,
    ModalBodyLeft,
    ModalBodyCenter,
    ModalBodyOnly,
    ModalFooterCenter,
    ModalFooterRight,
    ModalMainButton,
    ModalSecondaryButton,
    TextBlock,
    PreloaderWhite,
    PreloaderGrey,
    PreloaderBlue
]