import Axios from 'axios';
export const company = {
    state: () => ({
        suggestions: [],
        tradespots: [],
        timers: {
            suggestions: 0,
            tradespots: 0,
        },
        errors: {
            suggestions: '',
            tradespots: '',
        }
    }),
    mutations: {
        setSuggestions(state, payload) {
            state.suggestions = payload
        },
        setTradespots(state, payload) {
            state.tradespots = payload
        },
        setTimer(state, payload) {
            state.timers[payload.name] = payload.id
        },
        setError(state, payload) {
            state.errors[payload.name] = payload.message
        },
    },
    actions: {
        companyClearTimerTradespots(context) {
            context.commit('setTimer', {
                name: 'tradespots',
                id: '0',
            });
        },
        companyClearError(context) {
            context.commit('setError', {
                name: 'suggestions',
                message: '',
            });
            context.commit('setError', {
                name: 'tradespots',
                message: '',
            });
        },
        companyReset(context) {
            //Suggestions
            context.commit('setSuggestions', []);
            context.commit('setTimer', {
                name: 'suggestions',
                id: '0',
            });
            //Tradespots
            context.commit('setTradespots', []);
            context.commit('setTimer', {
                name: 'tradespots',
                id: '0',
            });
            context.dispatch('companyClearError')
        },
        companySuggestions(context, id) {

            Axios.get(`${process.env.VUE_APP_AJAX_HOST}/sanctum/csrf-cookie`);

            context.commit('setSuggestions', []);
            context.commit('setTradespots', []);
            context.dispatch('companyClearError');
            context.dispatch('companyClearTimerTradespots');

            var timer = context.state.timers['suggestions'];

            timer++;

            const response = Axios.get(`${process.env.VUE_APP_AJAX_HOST}/api/company/suggestions?company=${id}`)
                .then((response) => {

                    var id = context.state.timers['suggestions'];

                    if (id == timer) {
                        context.commit('setSuggestions', (response.data.items != undefined) ?
                            response.data.items : []);
                    }

                    //isLastRequet
                    return id == timer;
                }).catch((error) => {
                    var errorMessage;

                    if (error.response) {
                        errorMessage = error.response.data.message
                    } else if (error.request) {
                        errorMessage = error.request
                    } else {
                        errorMessage = error.message
                    }

                    var id = context.state.timers['suggestions'];

                    if (id == timer) {
                        context.commit('setError', {
                            name: 'suggestions',
                            message: errorMessage,
                        });
                    }
                });

            context.commit('setTimer', {
                name: 'suggestions',
                id: timer,
            });

            return response;
        },
        companyTradespots(context, params) {

            Axios.get(`${process.env.VUE_APP_AJAX_HOST}/sanctum/csrf-cookie`);

            context.commit('setTradespots', []);
            context.dispatch('companyClearError');

            var timer = context.state.timers['tradespots'];

            timer++;

            const response = Axios.get(`${process.env.VUE_APP_AJAX_HOST}/api/company/tradespots?company=${params.company}`)
                .then((response) => {

                    var id = context.state.timers['tradespots'];

                    if (id == timer) {
                        context.commit('setTradespots', (response.data.items != undefined) ?
                            response.data.items : []);
                    }

                    //isLastRequet
                    return id == timer;

                }).catch((error) => {
                    var errorMessage;

                    if (error.response) {
                        errorMessage = error.response.data.message
                    } else if (error.request) {
                        errorMessage = error.request
                    } else {
                        errorMessage = error.message
                    }

                    var id = context.state.timers['tradespots'];

                    if (id == timer) {
                        context.commit('setError', {
                            name: 'tradespots',
                            message: errorMessage,
                        });
                    }
                });


            context.commit('setTimer', {
                name: 'tradespots',
                id: timer,
            });

            return response;
        },
    },
    getters: {
        getSuggestions: (state) => {
            return state.suggestions
        },
        getTradespots: (state) => {
            return state.tradespots
        },
        getTimer(state, payload) {
            return state.timers[payload]
        },
        getTimers(state) {
            return state.timers
        },
        getError(state, payload) {
            return state.errors[payload]
        },
        getErrorsCompany(state) {
            return state.errors
        },
    }
}