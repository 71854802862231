import Axios from 'axios';
export const basket = {
    state: () => ({
        basketData: [],
        basketTotal: {},
        available: {},
    }),
    mutations: {
        sendBasketData(state, payload) {
            state.basketData = payload
        },
        sendBasketTotal(state, payload) {
            state.basketTotal = payload
        },
        sendBasket(state, payload) {
            state.basketData = payload.items
            state.basketTotal = payload.total
        },
        sendAvailable(state, payload) {
            state.available = payload
        },
    },
    actions: {
        addBasketItem(context, params) {
            context.dispatch("startLoading");
            context.commit('setPreloaderSaleInformation', true);
            return Axios.post(`${process.env.VUE_APP_AJAX_HOST}/api/basket`, params)
                .then((response) => {
                    context.commit('sendBasket', response.data.data);
                    return response.data.data
                })
                .finally(() => {
                    context.dispatch("stopLoading");
                    context.commit('setPreloaderSaleInformation', false);
                });
        },
        getBasketData(context) {
            context.dispatch("startLoading");
            return Axios.get(`${process.env.VUE_APP_AJAX_HOST}/api/basket`)
                .then((response) => {
                    context.commit('sendBasket', response.data.data);
                    return response;
                })
                .finally(() => {
                    context.dispatch("stopLoading");
                });
        },
        getAvailableData(context) {
            context.dispatch("startLoading");
            return Axios.get(`${process.env.VUE_APP_AJAX_HOST}/api/basket/available`)
                .then((response) => {
                    context.commit('sendAvailable', response.data.data);
                    return response;
                })
                .finally(() => {
                    context.dispatch("stopLoading");
                });
        },
        deleteBasketItem(context, id) {
            return Axios.delete(`${process.env.VUE_APP_AJAX_HOST}/api/basket/${id}`).then((response) => {
                context.commit('sendBasket', response.data.data);
            });
        },
        updateBasketItem(context, id) {
            return Axios.put(`${process.env.VUE_APP_AJAX_HOST}/api/basket/${id}`).then((response) => {
                context.commit('sendBasket', response.data.data);
            });
        },
        deleteBasketAll(context) {
            context.dispatch("startLoading");
            return Axios.delete(`${process.env.VUE_APP_AJAX_HOST}/api/basket/clear`).then((response) => {
                    context.commit('sendBasket', response.data.data);
                    context.dispatch("stopLoading");
                })
                .finally(() => {
                    context.dispatch("stopLoading");
                });
        },
    },
    getters: {
        getBasketData: (state) => {
            return state.basketData
        },
        gendBasketTotal: (state) => {
            return state.basketTotal
        },
        getAvailable: (state) => {
            return state.available
        },
    }
}