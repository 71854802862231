<template>
 <button class="modal-main-button">
   <slot>

   </slot>
 </button>
</template>

<script>
export default {
  name: "ModalMainButton"
}
</script>

<style scoped>
.modal-main-button {
  background: #3663F2;
  box-shadow: 0px 8px 24px rgb(54 99 242 / 35%), 0px 0px 48px rgb(54 99 242 / 20%);
  border-radius: 7px;
  font-weight: bold;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  border: none;
  padding:10px 30px;
  width: 45%;

}
.modal-main-button:hover {
  background: #1344E0;
}

@media (max-width: 768px) {
  .modal-main-button {
    padding:10px 10px;
    width: 55%;
  }
}
</style>