<template>
  <div class="loading">
    <img src="@/assets/loading.gif" alt="loading">
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style scoped>
.loading
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hwb(0deg 0% 100% / 20%);
    z-index: 10000;
}
.loading img{
    width: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>