<template>
  <div class="modal-footer">
    <slot>

    </slot>
  </div>
</template>

<script>
export default {
  name: "ModalFooterCenter"
}
</script>

<style scoped>
.modal-footer {
  padding-top: 20px 0;
  border-top: 1px solid #f5f5f5;
  display:flex;
  align-items:center;
  justify-content: center;
}
</style>