<template>
  <div class="custom-input-with-text mb-4">
    <div class="custom-input-with-text-text">
      {{ text }}
    </div>
    <div class="custom-input-with-text-value">
      <input
          v-model="input" 
          :name="name"
          :placeholder="placeholder"
          :required="required"
          :type="type"
          class="custom-input"
          :class="{'custom-input-with-text-error': error}"
      >
    </div>
    <small class="custom-input-with-text-error" v-if="required && error">Поле явлется обязательным</small>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    name: String,
    placeholder: String,
    text: String,
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default(){
        return new Date().toISOString().slice(0, 10)
      }
    },
  },
  data() {
    return {
      input: this.value,
    };
  },
  watch: {
    // при каждом изменении `select` эта функция будет запускаться
    input(newQuestion) {
      this.$emit('change', newQuestion)
    },
    value(newValue) {
      this.input = newValue
    },
  },
  name: "CustomInputWithText",
}
</script>

<style scoped>
.custom-input-with-text {
  display:flex;
  flex-direction: column;
  border-radius: 8px;
  background: #f2f3f4;
  width:350px;
  padding: 10px 15px;
}
.custom-input-with-text-text {
  font-size: 13px;
  color: #71747A;
}
.custom-input {
  border:none;
  background-color:#f2f3f4;
  width:100%;
cursor:text;
}
.custom-input:focus-visible {
  outline:none;
}
@media (max-width: 768px) {
  .custom-input-with-text {
    width:100%;
  }
}

.custom-input-with-text{
    border: 1px solid transparent;
}
.custom-input-with-text-error{
    border-color: #d79090;
}
.custom-input-with-text-error{
  color: #cf1c1c;
  padding-left: 3px;
}
</style>
