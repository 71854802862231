<template>

  <div class="floating-label">
    <input class="floating-input" v-model="input" :name="name" :type="passwordFieldType" placeholder=" ">
    <label>{{ text }}</label>
    <div class="switch-password" @click="switchVisibility"><font-awesome-icon :icon="iconType" class="icon alt eye-icon"/></div>
  </div>

</template>

<script>
export default {
  name: "CustomInputPassword",
  data() {
    return {
      input: this.value,
      passwordFieldType: "password",
      iconType: "eye"
    };
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      this.iconType = this.iconType === "eye" ? "eye-slash" : "eye";
    }
  },
  watch: {
    // при каждом изменении `select` эта функция будет запускаться
    input(newQuestion) {
      this.$emit('change', newQuestion)
    },
    value(newValue) {
      this.input = newValue
    },
  },
  props: {
    name: String,
    value: String,
    text: String
  }
}
</script>

<style scoped>
.switch-password {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #8d8d8d;
}
.floating-label {
  position:relative;
  margin-bottom:20px;
  /* max-width:350px; */
}
.floating-input {
  font-size:14px;
  display:block;
  width:100%;
  border-radius: 8px;
  background-color: #f2f3f4;
  border:none;
  height: 54px;
  padding: 15px 45px 0px 15px;
  /* max-width: 350px; */
  margin-top:15px;

}
.floating-input:focus {
  outline:none;
}
label {
  color:#999;
  font-size:14px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:15px;
  top:15px;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}
.floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
  top:5px;
  font-size:12px;
  color:#3663f2;
}
@media (max-width: 768px) {}
</style>
