import { createStore } from 'vuex'

import { sale } from './sale.js';

import { history } from './history.js';

import { user } from './user.js';

import { summarizing } from './summarizing.js';

import { qr } from './qr.js';

import { menu } from './menu.js';

import { news } from './news.js';

import { basket } from './basket.js';

import { company } from './company.js';

export default createStore({
    state: {
        loading: false
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
    },
    actions: {
        startLoading(context) {
            context.commit('setLoading', true);
        },
        stopLoading(context) {
            context.commit('setLoading', false);
        },
    },
    getters: {
        getLoading: (state) => {
            return state.loading
        },
    },
    modules: {
        sale: sale,
        history: history,
        user: user,
        summarizing: summarizing,
        qr: qr,
        menu: menu,
        news: news,
        basket: basket,
        company: company,
    },
})